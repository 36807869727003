import React from 'react';

const ErrorLayout = ({children}) => {
    return (
        <div>
            Error layout
        </div>
    );
};

export default ErrorLayout;
