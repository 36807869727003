import React from 'react';
import OperatorsContainer from '../containers/OperatorsContainer';

const OperatorsPage = () => {
    return (
        <>
            <OperatorsContainer />
        </>
    );
};

export default OperatorsPage;
