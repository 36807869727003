import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = (props) => {
    return (
        <>
            <ToastContainer />
        </>
    );
};

export default Toastify;
