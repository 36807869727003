import React from 'react';
import EditOperatorsContainer from '../containers/EditOperatorsContainer';

const EditOperatorsPage = () => {
    return (
        <>
            <EditOperatorsContainer />
        </>
    );
};

export default EditOperatorsPage;
