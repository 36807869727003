import React from 'react';
import DealerPriceContainer from '../container/DealerPriceContainer';

const DealerPricePage = () => {
    return (
        <>
            <DealerPriceContainer />
        </>
    );
};

export default DealerPricePage;
