import React from 'react';
import PricesContainer from '../containers/PricesContainer';

const PricesPage = () => {
    return (
        <>
            <PricesContainer />
        </>
    );
};

export default PricesPage;
