import React from 'react';
import EditLocationContainer from '../containers/EditLocationContainer';

const EditLocationPage = () => {
    return (
        <>
            <EditLocationContainer />
        </>
    );
};

export default EditLocationPage;
