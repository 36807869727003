import React from 'react';
import CategoriesContainer from '../containers/CategoriesContainer';

const CategoriesPage = () => {
    return (
        <>
            <CategoriesContainer />
        </>
    );
};

export default CategoriesPage;
