import React from 'react';
import ShowCityDealerContainer from '../container/ShowCityDealerContainer';

const ShowCityDealerPage = () => {
    return (
        <>
            <ShowCityDealerContainer />
        </>
    );
};

export default ShowCityDealerPage;
