import React from 'react';
import ShowPricesContainer from '../containers/ShowPricesContainer';

const ShowPricesPage = () => {
    return (
        <>
            <ShowPricesContainer />
        </>
    );
};

export default ShowPricesPage;
