import React from 'react';
import EditDealerContainer from '../container/EditDealerContainer';

const EditDealerPage = () => {
    return (
        <>
            <EditDealerContainer />
        </>
    );
};

export default EditDealerPage;
