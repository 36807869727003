import React from 'react';
import LimitContainer from '../containers/LimitContainer';

const LimitContainerPage = () => {
    return (
        <>
            <LimitContainer/>
        </>
    );
};

export default LimitContainerPage;
