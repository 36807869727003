import React from 'react';
import EditLimitContainer from '../containers/EditLimitContainer';

const EditLimitContainerPage = () => {
    return (
        <>
            <EditLimitContainer />
        </>
    );
};

export default EditLimitContainerPage;
