import React from 'react';
import CityDealerContainer from '../container/CityDealerContainer';

const CityDealerPage = () => {
    return (
        <>
            <CityDealerContainer />
        </>
    );
};

export default CityDealerPage;
