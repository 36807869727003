import React from 'react';
import AddLocationContainer from '../containers/AddLocationContainer';

const AddLocationPage = () => {
    return (
        <>
            <AddLocationContainer />
        </>
    );
};

export default AddLocationPage;
