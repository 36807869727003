import React from 'react';
import MyAccountContainer from '../containers/MyAccountContainer';

const MyAccountPage = () => {
    return (
        <>
            <MyAccountContainer />
        </>
    );
};

export default MyAccountPage;
