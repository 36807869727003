import AddMyEMSContainer from "../containers/AddMyEMSContainer";
// import AddMyEMSParcelContainer from "../containers/AddMyEMSParcelContainer";

const AddMyEMSParcelPage = () => {

    return <AddMyEMSContainer/>
    //  <AddMyEMSParcelContainer />;
};

export default AddMyEMSParcelPage;
