import React from 'react';
import AddCategoriesContainer from '../containers/AddCategoriesContainer';

const AddCategoriesPage = () => {
    return (
        <>
            <AddCategoriesContainer />
        </>
    );
};

export default AddCategoriesPage;
