import React from 'react';
import AddPricesContainer from '../containers/AddPricesContainer';

const AddPricesPage = () => {
    return (
        <>
            <AddPricesContainer />
        </>
    );
};

export default AddPricesPage;
