import React from 'react';
import AddDealerPriceContainer from '../container/AddDealerPriceContainer';

const AddDealerPricePage = () => {
    return (
        <>
            <AddDealerPriceContainer />
        </>
    );
};

export default AddDealerPricePage;
