import React from 'react';
import PaymentContainer from '../container/PaymentContainer';

const PaymentPage = () => {
    return (
        <>
            <PaymentContainer />
        </>
    );
};

export default PaymentPage;
