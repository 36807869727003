import LoginContainer from "../containers/LoginContainer";

const LoginPage = () => {
    return (
        <>
            <LoginContainer />
        </>
    );
};

export default LoginPage;
