import React from 'react';
import CountriesContainer from '../containers/CountriesContainer';

const CountriesPage = () => {
    return (
        <>
            <CountriesContainer />
        </>
    );
};

export default CountriesPage;
