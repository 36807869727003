import React from 'react';
import MeasurementUnitsContainer from '../containers/MeasurementUnitsContainer';

const MeasurementUnitsPage = () => {
    return (
        <>
            <MeasurementUnitsContainer />
        </>
    );
};

export default MeasurementUnitsPage;
