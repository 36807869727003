import React from 'react';
import LocationContainer from '../containers/LocationContainer';

const LocationPage = () => {
    return (
        <>
            <LocationContainer />
        </>
    );
};

export default LocationPage;
