import React from 'react';
import EditCategoriesContainer from '../containers/EditCategoriesContainer';

const EditCategoriesPage = () => {
    return (
        <>
            <EditCategoriesContainer />
        </>
    );
};

export default EditCategoriesPage;
