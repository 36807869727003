import React from 'react';
import EditPricesContainer from '../containers/EditPricesContainer';

const EditPricesPage = () => {
    return (
        <>
            <EditPricesContainer />
        </>
    );
};

export default EditPricesPage;
