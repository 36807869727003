import React from 'react';
import CompanyInfoContainer from '../containers/CompanyInfoContainer';

const CompanyInfoPage = () => {
    return (
        <>
            <CompanyInfoContainer />
        </>
    );
};

export default CompanyInfoPage;
