import React from 'react';
import AddCountriesContainer from '../containers/AddCountriesContainer';

const AddCountriesPage = () => {
    return (
        <>
            <AddCountriesContainer />
        </>
    );
};

export default AddCountriesPage;
