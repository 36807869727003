import React from 'react';
import AddOperatorsContainer from '../containers/AddOperatorsContainer';

const AddOperatorsPage = () => {
    return (
        <>
            <AddOperatorsContainer />
        </>
    );
};

export default AddOperatorsPage;
