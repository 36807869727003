import React from 'react';
import EditMeasurementUnitsContainer from '../containers/EditMeasurementUnitsContainer';

const EditMeasurementUnitsPage = () => {
    return (
        <>
            <EditMeasurementUnitsContainer />
        </>
    );
};

export default EditMeasurementUnitsPage;
