import React from 'react';
import AddMeasurementUnitsContainer from '../containers/AddMeasurementUnitsContainer';

const AddMeasurementUnitsPage = () => {
    return (
        <>
            <AddMeasurementUnitsContainer />
        </>
    );
};

export default AddMeasurementUnitsPage;
