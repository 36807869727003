import React from 'react';
import EditCountriesContainer from '../containers/EditCountriesContainer';

const EditCountriesPage = () => {
    return (
        <>
            <EditCountriesContainer />
        </>
    );
};

export default EditCountriesPage;
